<template>
    <v-model-table
        v-bind="tableDataSource"
        :columns="columns"
        :query="query"
    >
        <template v-slot:filters>
            {{ tableDataSource }}
            <slot name="filters" />
        </template>
    </v-model-table>
</template>

<script>
import _ from "lodash";

export default {
    name: "ActivityLogTable",
    props: {
        dataFunction: {
            type: Function,
            required: false,
            default: null,
        },
        query: {
            type: Object,
            required: false,
            default: () => {},
        },
    },
    computed: {
        /**
         * Returns the attributes that should be bound to the table.
         */
        tableDataSource() {
            if (this.dataFunction) {
                return {
                    dataFunction: this.dataFunction,
                };
            }

            if (this.service) {
                return {
                    service: this.service,
                };
            }

            return {
                module: "activityLogModule",
            };
        },
        columns() {
            const tableColumns = {
                created_at: {
                    label: "Date",
                    fullWidth: false,
                },
                model: {
                    label: "Data Source",
                    fullWidth: false,
                    formatter: (row) => {
                        const spaces = row.model.split("\\");
                        return spaces[spaces.length - 1];
                    },
                },
                column_name: {
                    label: "Column Name",
                    fullWidth: false,
                    formatter: (row) => _.startCase(_.camelCase(row.column_name)),
                },
                action_type: {
                    label: "Action Type",
                    fullWidth: false,
                    formatter: (row) => _.startCase(_.camelCase(row.action_type)),
                },
                original_value: {
                    label: "Previous Value",
                    fullWidth: false,
                },
                updated_value: {
                    label: "New Value",
                    fullWidth: false,
                },
                model_id: {
                    label: "Data Source ID",
                    fullWidth: false,
                    align: "right",
                },
            };

            return tableColumns;
        },
    },
};
</script>
