<template>
    <v-activity-log-table :data-function="getUserActivityLogs" :query="query" />
</template>

<script>
import ActivityLogTable from "@/components/application/activity-log/activity-log-table.vue";
import UserService from "@/services/modules/user-service";

export default {
    components: {
        "v-activity-log-table": ActivityLogTable,
    },
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            getUserActivityLogs: (payload) => UserService.getActivityLogs(this.user.user_id, payload),
            query: {
                page: 1,
            },
        };
    },
};
</script>
